:root {

    /* Colors: */
    --not-black-001131: #001131;
    --dark-green-0c7924: #0C7924;
    --dark-red-91101f: #91101F;
    --dark-gold-cb9e00: #CB9E00;
    --dark-blue-021e73: #021E73;
    --light-green-a5e2b2: #A5E2B2;
    --light-red-e09ba3: #E09BA3;
    --light-gold-fff3c9: #FFF3C9;
    --light-sky-a0d4f5: #A0D4F5;
    --light-blue-96b0f8: #96B0F8;
    --green-0fd139: #0FD139;
    --red-e01b32: #E01B32;
    --purple-6b1ceb: #6B1CEB;
    --primary-gold-ffc700: #FFC700;
    --primary-sky-4cb1f7: #4CB1F7;
    --primary-blue-0528f2: #0528F2;
    /* Font/text values */
    --unnamed-font-family-proxima-nova: Proxima Nova;
    --unnamed-font-style-normal: normal;
    --unnamed-font-style-italic: italic;
    --unnamed-font-weight-300: 18.75rem;
    --unnamed-font-weight-600: 37.5rem;
    --unnamed-font-weight-800: 50rem;
    --unnamed-font-weight-900: 56.25rem;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-size-18: 1.125rem;
    --unnamed-font-size-24: 1.5rem;
    --unnamed-font-size-28: 1.75rem;
    --unnamed-font-size-32: 2rem;
    --unnamed-font-size-36: 2.25rem;
    --unnamed-font-size-48: 3rem;
    --unnamed-font-size-60: 3.75rem;
    --unnamed-font-size-72: 4.5rem;
    --unnamed-character-spacing-0: 0rem;
    --unnamed-line-spacing-22: 1.375rem;
    --unnamed-line-spacing-29: 1.8125rem;
    --unnamed-line-spacing-34: 2.125rem;
    --unnamed-line-spacing-39: 2.4375rem;
    --unnamed-line-spacing-44: 2.75rem;
    --unnamed-line-spacing-58: 3.625rem;
    --unnamed-line-spacing-73: 4.5625rem;
    --unnamed-line-spacing-87: 5.4375rem;    
    }        


   .overview .title {
        position: absolute;
        top: 21.625rem;
        left: 14.5rem;
        width: 50rem;
        height: 16.5rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-900) var(--unnamed-font-size-72)/5.5rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--primary-blue-0528f2);
        text-align: left;
        font: normal normal 900 4.5rem/5.5rem Montserrat;
        letter-spacing: 0rem;
        color: #0528F2;
        opacity: 1;
        font-family: "Montserrat";
    }

   .overview .body {
        position: absolute;
        top: 41.3125rem;
        left: 14.5rem;
        width: 45.5rem;
        height: 8.25rem;
        font: var(--unnamed-font-style-normal) normal normal var(--unnamed-font-size-36)/var(--unnamed-line-spacing-44) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--not-black-001131);
        text-align: left;
        font: normal normal normal 2.25rem/2.75rem Montserrat;
        letter-spacing: 0rem;
        color: #001131;
        opacity: 1;
    }

   .overview .calltoaction {
        position: absolute;
        top: 54.125rem;
        left: 14.5rem;
        width: 49.125rem;
        height: 9rem;
        background: #0528F2 0% 0% no-repeat padding-box;
        color:white;
        line-height: 2rem;
        padding:1rem 0;
        box-sizing: border-box;
        cursor: pointer;
    }

   .overview .calltoaction p {
        margin-top:0;
        padding-top:25px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-34) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 3.75rem/2.125rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
        line-height: 2rem;
        vertical-align: center;
        border:none;
    }

   .overview .value-proposition-background {
        position:relative;
        width: 106.75rem;
        height: 78.875rem;
        margin-bottom: 5rem;
        background: var(--primary-blue-0528f2) 0% 0% no-repeat padding-box;
        background: #0528F2 0% 0% no-repeat padding-box;
        opacity: 1;
    }

   .overview .centering-container {
        position: static;
        margin:auto;
        width:38.625rem;
        height:1rem;
    }

  .overview .value-proposition-title {
        position: absolute;
        top: 7.4375rem;
        left: 7.3125rem;
        width: 32.3125rem;
        height: 14.25rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/4.375rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal bold 4.5rem/4.375rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .value-proposition-body {
        position:absolute;  
        top: 18.875rem;
        left: 7.3125rem;
        width: 38.625rem;
        height: 10.875rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-29) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 1.5rem/1.8125rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .value-proposition-graphic {
        position: absolute;
        top: 0.25rem;
        left: 60rem;
        width: 39rem;
        height: 41.25rem;
        background: transparent url('img/value-proposition-graphic.svg') 0% 0% no-repeat padding-box;
        opacity: 1;
        background-size: contain;
    }


   .overview .venn-diagram-circle {
        position: absolute;
        top: 42.875rem;
        left: 4.625rem;
        width: 32.125rem;
        height: 32.125rem;
        border: 0.625rem solid #FFFFFF;
        opacity: 1;
        border-radius: 100% 100%;
        margin:0;
        box-sizing: border-box;
        
    }
    .overview #right {
        left: 26.625rem;
    }

   .overview .venn-diagram-title {
        position: absolute;
        top: 47.5rem;
        left: 15.375rem;
        width: 10.625rem;
        height: 4.875rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-900) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-39) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal 900 2rem/2.4375rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

    .overview #blockchain {
        left: 36.625rem;
    }

   .overview .venn-diagram-body {
        position: absolute;
        top: 54.0625rem;
        left: 7.3125rem;
        width: 19rem;
        height: 9.75rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-39) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal bold 2rem/2.4375rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .venn-diagram-center {
        position:absolute;
        top: 56.5rem;
        left: 26.625rem;
        width: 9.75rem;
        height: 4.875rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-900) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-39) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal 900 2rem/2.4375rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
        margin:0;
    }

   .overview .value-proposition-subtitle {
        position: absolute;
        top: 50.6875rem;
        left: 61.875rem;
        width: 28.6875rem;
        height: 5.5rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/5.5rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal bold 4.5rem/5.5rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .value-proposition-subbody {
        position: absolute;
        top: 56.6875rem;
        left: 61rem;
        width: 38.0625rem;
        height: 16.3125rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-29) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 1.5rem/1.8125rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

    .overview #blockchains {
        left: 37.125rem;
    }

   .overview .benefits {
        position: relative;
        width: 106.75rem;
        height: 54.9375rem;
        background: var(--dark-blue-021e73) 0% 0% no-repeat padding-box;
        background: #021E73 0% 0% no-repeat padding-box;
        opacity: 1;
        margin-bottom: 5rem;        
        display: flex;    
        justify-content: space-evenly;    
    }

   .overview .benefit {
        position: static;
        width: 20.0625rem;
        height:40rem;
        padding:1rem;
        margin-top:12rem;
    }
    
   .overview .benefits-title {
        position: absolute;
        top: 4.3125rem;
        width: 100vw;
        height: 5.5rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/5.5rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 4.5rem/5.5rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .benefits-icon-1 {
        margin:auto;
        width: 17.875rem;
        height: 11.3125rem;
        background: transparent url('img/Scenes-24.svg') 0% 0% no-repeat padding-box;
        opacity: 1;
        background-size: contain;
    }

   .overview .benefits-icon-2 {        
        margin:auto;
        width: 10.875rem;
        height: 12.4375rem;
        background: transparent url('img/Scenes-25.svg') 0% 0% no-repeat padding-box;
        opacity: 1;
        background-size: contain;
    }

   .overview .benefits-icon-3 {
        margin:auto;
        width: 14.0625rem;
        height: 11.125rem;
        background: transparent url('img/Scenes-26.svg') 0% 0% no-repeat padding-box;
        opacity: 1;
        background-size: contain;
    }

   .overview .underline {
        position: relative;
        top: 6.5625rem;
        left:5rem;
        width: 9rem;
        line-height:0;
        height: 0rem;
        border: 0.0625rem solid var(--primary-blue-0528f2);
        background-color: var(--primary-blue-0528f2);
        opacity: 1;
        box-sizing: content-box;
    }
   .overview .benefits-subtitle {
        position: relative;
        text-align: center;
        left: 0rem;
        top:5rem;
        width: 19.875rem;
        height: 2.4375rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-39) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal 800 2rem/2.4375rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .benefits-body {
        position: relative;
        top: 8.25rem;
        
        width: 19.875rem;
        height: 9.1875rem;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal medium 1.5rem/1.8125rem Montserrat;
        font-size: 1.5rem;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .comparison {
        height: 50.875rem;
        position:relative;
        width: 106.75rem;
        margin-bottom: 5rem;
        display:flex;
        justify-content: center;
    }

   .overview .comparison-left {
        height: 50.875rem;
        background: var(--not-black-001131) 0% 0% no-repeat padding-box;
        background: #001131 0% 0% no-repeat padding-box;
        opacity: 1;
        flex-grow: 1;
    }

   .overview .comparison-right {
        left: 53.42rem;
        height: 50.875rem;
        background: var(--primary-blue-0528f2) 0% 0% no-repeat padding-box;
        background: #0528F2 0% 0% no-repeat padding-box;
        opacity: 1;
        flex-grow: 1;
    }

   .overview .comparison-title {
        position: relative;
        top: 7.0625rem;
        left: 16.3125rem;
        width: 22.5rem;
        height: 5.5rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/4.375rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 4.5rem/4.375rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .comparison-division {
        position: absolute;
        top: 0.375rem;
        left: 41.5%;
        width: 18.125rem;
        height: 49.0625rem;
        background: transparent url('img/Sign_signs, navigation, direction, left, right.svg') 0% 0% no-repeat padding-box;
        opacity: 1;
        z-index: 2;
        background-size: contain;
    }

   .overview .comparison-body {
        box-sizing: border-box;
        position: relative;
        top: 16.5rem;
        left: 8.4375rem;
        width: 35.75rem;
        height: 24.8125rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-36)/var(--unnamed-line-spacing-44) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 2.25rem/2.75rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .contact {
        position:relative;
        width: 106.75rem;
        height: 19.625rem;
        background: var(--primary-gold-ffc700) 0% 0% no-repeat padding-box;
        background: #FFC700 0% 0% no-repeat padding-box;
        opacity: 1;
        display:flex;
    }

   .overview .section {
        height:100%;
        width:50%;
        max-width: 50vw;
    }

   .overview .discord {
        position: static;
        display: inline-block;
        margin-top: 6.3125rem;
        margin-left: 11vw;
        width: 25.625rem;
        height: 9.25rem;
        background: transparent url('img/GWP3ANV.png') 0% 0% no-repeat padding-box;
        opacity: 1;
        cursor: pointer;
        background-size: contain;
    }

   .overview .contact-title {
        position: relative;
        top: 2.9375rem;
        left: 0;
        width: 34.125rem;
        height: 3.625rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-48)/var(--unnamed-line-spacing-58) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 3rem/3.625rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .contact-email, .MailChimp input {
        position: relative;
        top: 4.5rem;
        left: 0;
        width: 34.125rem;
        height: 3.375rem;
        border: 0.1875rem solid var(--primary-blue-0528f2);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.1875rem solid #0528F2;
        opacity: 1;
        padding:1rem;
        font-weight: bold;
        color:black;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-34) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--not-black-001131);
        font: normal normal bold 1.75rem/2.125rem Montserrat;
        letter-spacing: 0rem;
        color: #001131;
        opacity: 1;
    }
    .MailChimp input {
			top: 1rem;
		}
    .MailChimp input ::placeholder , .overview .contact-email ::placeholder {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-34) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--not-black-001131);
        font: normal normal bold 1.75rem/2.125rem Montserrat;
        letter-spacing: 0rem;
        color: #001131;
        opacity: 1;
    }

		.MailChimp, .MailChimp > div > div {
						margin-top:46px;
		}
		 .MailChimp > div > div {
						text-align:center;
		}

   .overview .linkbutton, .MailChimp button {
        position: relative;
        margin-top: 6.625rem;
        margin-left: 12rem;
        top: 6.625rem;
        left: 12rem;
        width: 12.5rem;
        height: 4rem;
        background: #314CE8 0% 0% no-repeat padding-box;
        opacity: 1;
        outline:none;
        border: 1px solid var(--primary-blue-0528f2);
        box-sizing: border-box;
        padding:0.9rem 1rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-34) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 1.75rem/2.125rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
        vertical-align: text-top;
        cursor:pointer;
        border: 3px solid #0528F2;

    }
    .MailChimp button {
			margin-top:0;
			left:0;
			top: 2rem;
		}
    
   .overview .examples {
        position:relative;
        margin-top: -10rem;
        height:70rem;
    }
    .overview #token {
        top: 5.375rem;
        left: 21.375rem;
        width: 25.375rem;
        margin:0;
    }

    .overview #example {
        position: relative;
        top:22rem;
        left:18rem;
        width: 30.5333rem;
    }

   .overview .examples-title {
        position: absolute;
        top: 5.5625rem;
        left: 3.1875rem;
        width: 22.8125rem;
        height: 5.5rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/5.5rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--not-black-001131);
        text-align: left;
        font: normal normal bold 4.5rem/5.5rem Montserrat;
        letter-spacing: 0rem;
        color: #001131;
        opacity: 1;
    }

   .overview .examples-subtitle {
        position: absolute;
        top: 26rem;
        left: 23rem;
        width: 22.125rem;
        height: 3.625rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800) var(--unnamed-font-size-48)/var(--unnamed-line-spacing-58) Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--dark-blue-021e73);
        text-align: center;
        font: normal normal 800 3rem/3.625rem Montserrat;
        letter-spacing: 0rem;
        color: #021E73;
        opacity: 1;
    }

   .overview .examples-body {
        position: absolute;
        top: 34.6875rem;
        left: 19.75rem;
        width: 28.625rem;
        height: 9.0625rem;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--dark-blue-021e73);
        text-align: center;
        font: normal normal medium 2.25rem/2.75rem Montserrat;        
        font-size:2.25rem;
        letter-spacing: 0rem;
        color: #021E73;
        opacity: 1;
    }

   .overview .examples-graphic {     
        position:absolute;
        top: 13.375rem;
        left: 56.1875rem;
        width: 26.9375rem;
        height: 49.5625rem;
        background: transparent url('img/IMG_2F4E2B69CB45-1.png') 0% 0% no-repeat padding-box;
        opacity: 1;
        background-size: contain;

    }

   .overview .github {
        position: relative;
        width: 106.75rem;
        height: 40.3125rem;
        background: var(--primary-blue-0528f2) 0% 0% no-repeat padding-box;
        background: #0528F2 0% 0% no-repeat padding-box;
        opacity: 1;
    }

   .overview .github-graphic {   
        position: absolute;
        top: 4.375rem;
        left: 14.375rem;
        width: 39.8125rem;
        height: 34.8125rem;
        background: transparent url('img/How work_settings, options, preferences, screwdriver, gear, man.svg') 0% 0% no-repeat padding-box;
        opacity: 1;  
        background-size: contain;
    }

   .overview .github-title {
        position:absolute;
        top: 8.0625rem;
        left: 57.125rem;
        width: 25.625rem;
        height: 11rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/5.5rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal bold 4.5rem/5.5rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .github-hoop-logo {
        position:absolute;
        top: 6.875rem; 
        left: 82.6875rem; 
        width: 12.125rem; 
        height: 12.125rem;
        background-image: url('/Token Host Brand Assets/SVG/White Logo.svg');
        opacity: 1;        
        background-size: contain;
    }
    
   .overview .github-body {
        position:absolute;
        top: 23.125rem;
        left: 57.125rem;
        width: 28.625rem;
        height: 3.625rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-29) Montserrat;
        font-family: Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal medium 1.5rem/1.8125rem Montserrat;
        font-size:1.5rem;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .footer {        
        position:relative;
        height: 22.5rem;
        width: 106.75rem;
        background: var(--primary-gold-ffc700) 0% 0% no-repeat padding-box;
        background: #FFC700 0% 0% no-repeat padding-box;
        opacity: 1;  
        margin-bottom: 10rem;   
    }

   .overview .footer-title {
        position:relative;
        top: 4.875rem;
        left: 21.24vw;
        width: 34rem;
        height: 5.5rem;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/5.5rem Montserrat;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal bold 4.5rem/5.5rem Montserrat;
        letter-spacing: 0rem;
        color: #FFFFFF;
        opacity: 1;
    }

   .overview .footer-graphic {    
        position:absolute;
        top: 4.8125rem;
        left: 51.904vw;
        width: 14.8125rem;
        height: 14.75rem;
        background: transparent url('img/Group 63.svg') 0% 0% no-repeat padding-box;
        opacity: 1; 
        background-size: contain;
    }

    .overview #footer {
        top: 12.125rem;
        left: 38.542vw;
        width: 13.125rem;
        height: 4rem;
        margin:0;
    }

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');            

    .overview {overflow-x:hidden;padding-bottom:5rem;}    

    .overview {padding:0;margin:0;}

    * {    
        font-family:  "Montserrat";
        font-size: 15px;
        max-width: 100%;
    }
    
    @media only screen and (max-width:1700px) {
        * {font-size:13px}
    }

    @media only screen and (max-width:1500px) {
        * {font-size:12px}
        
       .overview .footer {        
            height: 23.5rem;
        }
    }

    @media only screen and (max-width:1400px) {
        * {font-size:11px;}
    }

    @media only screen and (max-width:1300px) {
        * {font-size:10px;}
    }

    @media only screen and  (max-width:1100px) {
       .overview .footer-graphic {
            left:80%;
        }
       .overview .footer-title {
            left:25%;
        }
        #footer {
            left:27rem;
        }
    }

    @media only screen and (max-width:1050px) {
        
       .overview .comparison-division {
            display:none;
        }

       .overview .comparison-title {
            left:10%;
        }
       .overview .comparison-body {
            left:10%;
        }

       .overview .github-graphic {
            left:2rem;
        }

       .overview .github-title {
            left:45rem;
        }

       .overview .github-hoop-logo {
            left:71rem;
        }

       .overview .github-body {
            left:45rem;
        }

       .overview .value-proposition-background {
            height:130rem;
            float: left;
        }

       .overview .value-proposition-title {
            position:static;
            height:fit-content;
            margin-top: 6rem;
            margin-bottom: 0;
        }

       .overview .value-proposition-body {
            position:static;
            margin-bottom:20rem;
        }
       .overview .value-proposition-graphic {
            position:static;
        }

       .overview .value-proposition-subtitle {
            position:static;
            margin-top:4rem;
        }
        
       .overview .value-proposition-subbody {
            position:static;
            margin-top:1rem;
            margin-bottom:20rem;

        }

        
       .overview .venn-diagram {
            position: relative;
            top:-10rem;
        }
        
       .overview .venn-diagram-circle {
            left:0;
        }

       .overview .venn-diagram-title {
            left: 10.75rem;
        }

        .overview #right {
            left: 0;
            top:68rem;
        }

       .overview .venn-diagram-center {
            left: 11.375rem;
            top:69rem;
        }

        .overview #blockchain {
            left: 10.75rem;
            top:78rem;
        }
       .overview .venn-diagram-body {
            left: 10.75rem;
        }
        .overview #blockchains {
            left: 10.75rem;
            top: 84rem;
        }
       .overview .value-proposition-background {
            height:190rem;
        }
    }

    @media only screen and (max-width:950px) {
       .overview .value-proposition-subtitle {
            top:105rem;
            left:7.3125rem;
        }
       .overview .value-proposition-subbody {
            top:113rem;
            left:7.3125rem;
        }
    }

    @media only screen and (max-width:850px) {
       .overview .github-graphic {
            display: none;
        }

       .overview .github-title {
            left:10rem;
        }

       .overview .github-hoop-logo {
            left:36rem;
        }

       .overview .github-body {
            left:10rem;
        }

       .overview .comparison {
            height:101rem;
            display: block;
        }
       .overview .comparison-left {
            width:100%;
            position: static;
            display:block;
        }
       .overview .comparison-right {
            width:100%;
            position: static;
            display:block;
        }

       .overview .comparison-title {
            position: static;
            display:block;
            margin:auto;
            padding-top:1rem;
        }

       .overview .comparison-body {
            position: static;
            display:block;
            margin:auto;
            padding:3rem 4rem 0 1rem;
        }


        .overview #example {
            left:4vw;
        }

       .overview .examples-graphic {
            left:54vw;
        }

        .overview #token {
            left:8vw;
        }
        
    }

    @media only screen and (max-width:800px) {
       .overview .title{
            left:8vw;
            font-size:3rem;
            width:40rem;
            line-height: 4rem;
        }

       .overview .body {
           top:35rem;
            left:8vw;
            font-size:1.5rem;
            width:33rem;
        }

       .overview .calltoaction {
            left:8vw;
            top:47rem;

        }

        .wedge {
            min-height:100vh;
        }
       .overview .contact {
            display: block;
            height: 40rem;
        }
       .overview .section {
            display: block;
            height:50%;
            width:100%;
            max-width: 100%;            
            float: left;

        }
       .overview .discord {
            display: block;
            margin:auto;
            margin-top: 6rem;
        }
       .overview .contact-title {
            margin:auto;
            margin-top: 0;
            top:0;
        }

				.MailChimp button {
						display: block;
						margin: 0 auto auto;
				}
       .MailChimp input{
            position: static;
            display: block;
            margin:auto;
            width:30rem;
        }
       .overview .contact-email {
            position: static;
            display: block;
            margin:auto;
            margin-top: 2rem;
            width:30rem;
        }

        .overview #contact {
            margin:auto;
            margin-top:2rem;
            display: block;
        }

        
    }

    @media only screen and (max-width:650px) {
       .overview .title{
            left:4vw;
            font-size:3rem;
            width:40rem;
        }

       .overview .body {
            left:4vw;
            font-size:1.5rem;
            width:33rem;
        }

       .overview .calltoaction {
            left:4vw;
        }

       .overview .benefits {
            display: inline-block;
            height:120rem;
            padding-top:12rem;
        }

       .overview .benefit {
            margin:auto;
            height:35rem;
        }

       .overview .benefits-subtitle {
            top:2rem;
        }

       .overview .underline {
            top:3rem;
        }
       .overview .benefits-body {
            top:6rem;
        }        
        
       .overview .examples {
            height:100rem;
            position: static;
        }
       .overview .examples-title {
            position: static;
            width:100%;
            text-align: center;
            margin-top:12rem;
        }
        .overview #example {
            display: block;
            margin:0;
            position: static;
            height:25rem;
            margin:auto;            
            margin-top: 3rem;

        }

       .overview .examples-graphic {
            position: static;
            display: block;
            margin:auto;
        }
        .overview #token {
            position: static;
            display: block;
            margin:auto;
            margin-top:5rem;
        }
       .overview .centering-container {
            width:32rem;
        }
       .overview .value-proposition-title {
            font-size:2.9rem;
        }
       .overview .value-proposition-subtitle {
            font-size:2.9rem;
        }

        
        
    }

    @media only screen and (max-width:630px){
        .overview .footer-graphic {
            width: 7.40625rem;
            height: 7.375rem;
        }

        .overview .footer-title {
            left:10%;
        }
    }

    @media only screen and (max-width:550px) {
        
       .overview .github-title {
            left:2rem;
            font-size: 3.5rem;
            line-height: 3.8rem;
        }

       .overview .github-hoop-logo {
            left:22rem;
            height: 10rem;
            width: 10rem;
        }

       .overview .github-body {
            left:2rem;
        }
        

       

    }

    @media only screen and (max-width:400px) {
        .overview {
            font-size:9px;
        }
        .overview .title {
            font-size:2.5rem;
            line-height: 3.5rem;
            max-width: 80%;
        }

       .overview .comparison-title {
            font-size:3rem;
        }

       .overview .comparison-body {
            font-size:1.8rem;
        }
       .overview .footer-title {
            font-size:3rem;
            width:100%;
            left:0;
            text-align: center;
        }
       .overview .footer {
            float:left;
        }
        .overview #footer {
            display: block;
            position: static;
            margin:auto;            
            margin-top:11rem;
        }

    }

    @media only screen and (max-width:350px) {
        .overview #active {
            left:2rem;
        }
       .overview .navbutton {
            left:13rem;
        }
    }

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');            
    * {    
        font-family:  "Montserrat";
    }
