body {
    margin: 0;
    overflow-x: hidden;
    max-width: 100%;
}



::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #666;
  z-index: 1;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

.top-background {
  position: absolute;
  z-index:-1;
  top: -30vw;
  left:-10vh;
  width:150vw;
  min-width: 600px;
  max-width: 150vw;
  display: inline-block;
  height: 200vh;
  background-image: url('../src/components/img/Token BIG-01.png');
  background-position: top left;
  background-size:contain;
  background-repeat:no-repeat;
}

.logo {
  position:absolute;
  top: 4.9375rem;
  left: 5vw;
  width: 38rem;
  max-width: 95vw;
  height: 8rem;
  background: transparent url('../src/components/Token Host Brand Assets/SVG/Wordmark.svg') 0% 0% no-repeat padding-box;
  opacity: 1;
  background-size: contain;
}

.navbutton {
  position: absolute;
  top: 6.75rem;
  right: 10rem;
  width:fit-content;
  height: 2.125rem;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-34) Montserrat;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--primary-blue-0528f2);
  text-align: left;
  font: normal normal bold 1.75rem/2.125rem Montserrat;
  letter-spacing: 0rem;
  color:#021E73;
  opacity: 1;
  line-height:1.5rem;
  cursor:pointer;
  text-decoration: none;
  padding-bottom: 0.125rem;
  z-index: 2;
}

#overviewlink {
  right: 37.5rem;
}
.active {
  color: #0528F2;
  border-bottom: 0.1875rem solid #0528F2;  
}


.wedge {
  height:75rem;
  min-height:90vh;
  pointer-events: none;
}


.upload-cloud-graphic {
  position: absolute;
  top: 16.4375rem;
  left: 67.0625rem;
  width: 41.25rem;
  height: 46.875rem;
  background-image:url('../src/components/img/Scenes_upload.svg');
  background-position: center right;
  background-size:contain;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: contain;
}

.navbox-grid {
  position: absolute;
  top:15rem;
  left:45px;
  display: grid;
  grid-template-columns: 27rem 1fr 27rem;
  grid-template-rows: 20rem 20rem 1rem;
  width:60rem;
  gap:2rem 1rem;
  max-width: 90vw;

}

.navbox {

}

.navbox .title {
  font-size:2.5rem;
  color:#0528F2; 

}

.navbox .body {
  font-size:1.5rem;

}

.navbox .link {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 22px 1fr 1fr;
  text-align: center;
  vertical-align: text-top;
  font-size:1.7rem;
  height: 4rem;
  background: #FFD338 0% 0% no-repeat padding-box;
  color:white;
  line-height: 2rem;
  padding:1rem 0;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;
  z-index: 2;
}

.navbox input {
  width: 34.125rem;
  height: 3.375rem;
  border: 0.1875rem solid var(--primary-blue-0528f2);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.1875rem solid #0528F2;
  opacity: 1;
  padding:1rem;
  font-weight: bold;
  color:black;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-34) Montserrat;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--not-black-001131);
  font: normal normal bold 1.75rem/2.125rem Montserrat;
  letter-spacing: 0rem;
  color: #001131;
  opacity: 1;
}

#explorer-wedge {
  height: 65rem;
  right: 20rem;
}

#explorer{
  right: 10rem;
}

#AppBuilder {
  right: 25rem;
}

.mobile-container {
  left:0;
  width:100vw;
  max-width:100vw;
  position: absolute;
}

.container {
  margin:auto;
  width:106.75rem;
  position: relative;
}

#desktop {
  display: static;
}
#mobile {
  opacity: 0;
  height: 0;
  }

  #hash {
    word-break: break-all;
    max-width:80vw;
    min-width: 0px;
  }
@media only screen and (max-width:1050px) {
 .upload-cloud-graphic {
       display:none;
   }
   #date {
    width:18ch;
   }
   
  .navbutton{
    top: 130px;
  }

  #explorer{
    /* left: 32.75rem; */
    left: 24rem;
  }

  #AppBuilder{
    /* left: 20.75rem; */
    left: 12rem;
  }

  #overviewlink{
    /* left: 10.75rem; */
    left: 2rem;
  }

  .logo {
    left:2rem;
    max-width: 93vw;
  }

  
}

@media only screen and (max-width:800px){


.navbutton {
  top:13rem;
}
}

@media only screen and (max-width:680px) {
  #mobile {
  opacity: 1;
  height: auto;
  
  }
  #desktop {
    display: none;
  }
}

@media only screen and (max-width:630px) {

.navbox-grid {
  grid-template-columns: 100%;
  grid-template-rows: 20rem 1fr 20rem 20rem 1fr 20rem 1rem 1fr 1rem;
  gap:0.1rem;
  width:34rem;
  position: static;
  margin: auto;
  margin-top:20rem;
}

#explorer-wedge {
  height: 110rem;
}
}

@media only screen and (max-width:400px) {
    #overviewlink {
        left:2rem;
    }
   .navbutton {
        left:13rem;
    }
}
